<template>
  <div>
    <!-- <ParticipationHeader
      :question="question"
     />
    <ParticipationSubHeader :question="question" /> -->
    <!--- Dynamic component -->
      <!-- <components :content="question" v-model="answer" ref="child" :is="paritciaptionComponents"></components> -->
    <!--- Dynamic component -->
    <div class="md-layout mt-4">
      <div class="md-layout-item md-size-50">
        <FileUploadComponent 
         :requiredLabel="true"
         label="Upload Audio File" class="w-full"
         path="answer"
         @input="handleFileUpload"
         />
      </div>

      <div class="row">
    <div class="toggle" @click="toggle">TOGGLE</div>

    <audio-recorder v-if="showRecorder"
      upload-url="some url"
      filename="ninja3"
      format="wav"
      :attempts="3"
      :time="2"
      :headers="headers"
      :before-recording="callback"
      :pause-recording="callback"
      :after-recording="callback"
      :select-record="callback"
      :before-upload="callback"
      :successful-upload="callback"
      :failed-upload="callback"
      :bit-rate="256"/>

    <audio-player :src="mp3" v-if="!showRecorder"/>
  </div>


    </div>
    <div class="md-layout mt-4">
      <div class="md-layout-item md-size-50">
       {{displayText}}
      </div>
    </div>

    <!-- <QuestionAction
      @on-previous="onPrevious"
      @on-next="onNext"
      @save="SaveAnswer"
      @submit="SaveAnswer"
      :currentPage="currentPage"
      :total="total"
    /> -->
    
  </div>

  
</template>

<script>
// import ParticipationHeader from "@/components/molecule/question/ParticipationHeader";
// import ParticipationSubHeader from "@/components/molecule/question/ParticipationSubHeader";
// import QuestionAction from "@/components/molecule/question/QuestionAction";
// import questionParicipationComponents from "@/components/molecule/question-participation";
import { mapActions, mapMutations } from 'vuex';
import { slugToComponent } from '@/utils'
import { query } from "@/utils/queryString";

import {SpeechToText} from "@/store/speech-to-text";

import {
  FileUploadComponent,
} from "@/components"



export default {
  components: {
    // ParticipationHeader,
    FileUploadComponent,
    // ParticipationSubHeader,
    // QuestionAction,
    // ...questionParicipationComponents
  },
  data() {
    return {
      paritciaptionComponents: '',
      answer: "",
      currentIndex: 1,
      question: {},
      meta: {},
      content: {},
      audio:'',
      displayText:'',
      sampleBody: {
        "exam_enroll_id":"a768851a-1ef5-4002-a3a3-95081c5a5eec",
        "question_test_id":"8d2833f5-498a-401c-9d6f-3341670bd52d",
        "question_id":"60052c2c-15d4-43da-b10a-767de58f1696",
        "test_id":"a6a97988-e98f-4c8c-921a-954e00f75b52",
        "active" : true,
        "content_percentage": 92.00,
        "form_percentage": null,
        "grammar_percentage": null,
        "fluency_percentage": 67.00,
        "pronunciation_percentage": 87.00,
        "spelling_percentage": null,
        "discourse_percentage": null,
        "vocabulary_percentage": null,
        "development_structure_coherence_percentage": null,
        "general_linguistics_percentage": null,
        "answer_sheet_details": {
            "evaluation_details": [],
            "content" : 4, 
            "form" : null, 
            "grammar" : null,  
            "fluency" : 3, 
            "pronunciation" : 5, 
            "spelling" : null,
            "discourse" : null, 
            "vocabulary" : null,
            "matched_keywords" : 5,
            "keyword_out_of_order" : 1,
            "word_count" : 30,
            "line_count" : 1,
            "insertion_count" : 0,
            "omission_count" : 0,
            "replacement_count" : 0,
            "pair_match_count" : null,
            "correct_answer_count" : null,
            "wrong_answer_count" : null,
            "development_structure_coherence" : null, 
            "general_linguistics" : null,

            "question_option_id" : null,
            "question_hints_id" : null,
            "answer" : "This is an answer for a test which is taken for a Read Aloud",
            "comment" : ""
        }
      },
       mp3: '/demo/example.mp3',
        showRecorder: true,
        headers: {
          'X-Custom-Header': 'some data'
        }
    };
  },
  computed: {
    examEnrollId() {
      return this.$route.query.enrollId
    },
    testId() {
      return this.$route.query.testId
    },
    currentPage() {
      return this.meta.current_page
    },
    total() {
      return this.meta.total
    }
  },
  methods: {

     callback (msg) {
        console.log('Event: ', msg)
      },
      toggle () {
        this.showRecorder = !this.showRecorder
      },
    ...mapMutations({
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actExamEnrolledQuestions: "examination/actExamEnrolledQuestions",
      actSaveAnswerSheet: "examination/actSaveAnswerSheet",
    }),

    handleFileUpload(value){

      let referenceText = ""
           
     SpeechToText(value, referenceText).then((response) => {
        if(response){
          this.displayText = `RECOGNIZED: Text=${response.DisplayText}`
          this.sampleBody.answer_sheet_details.evaluation_details=response;
          this.actSaveAnswerSheet(this.sampleBody)
          .then(({ message }) => {
            this.setShowSnackbar(message);
            this.answer = ""
          });

        }else{
          this.displayText = 'ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.';
        }
     })
    },
    onNext() {
      if(this.currentPage < this.total ) {
      
        this.answer = ""
        query.set('page', this.currentPage + 1);
        this.actExamEnrolledQuestions(`${this.examEnrollId}?${query.get()}`)
        .then(({ items, meta }) => {
          this.question = items[0];
          this.paritciaptionComponents = slugToComponent(this.question.question_type_slug);
          this.answer = this.setAnswer()
          this.meta = meta
        });
      }else {
        this.setShowSnackbar('No more question next');
      }
    },
    onPrevious() {
      if(this.currentPage > 1 ) {
        this.answer = ""
        query.set('page', this.currentPage - 1);
        this.actExamEnrolledQuestions(`${this.examEnrollId}?${query.get()}`)
        .then(({ items, meta }) => {
          this.question = items[0];
          this.paritciaptionComponents = slugToComponent(this.question.question_type_slug);
          this.answer = this.setAnswer()
          this.meta = meta
        });
      }else {
        this.setShowSnackbar('No more question in previous, Goto next');
      }
     
    },
    SaveAnswer() {

      let data = {
          exam_enroll_id: this.examEnrollId,
          question_test_id: this.question.question_test_id,
          answer_sheet_details: [
            {
              question_option_id: null,
              answer: this.answer // Child / dynamic components value.
            }
          ]
      }
      this.actSaveAnswerSheet(data)
      .then(({ message }) => {
        this.setShowSnackbar(message);
        this.answer = ""
      });
    },
    setAnswer() {
      return this.question.answer_sheet[0] ? this.question.answer_sheet[0].answer_sheet_details ? this.question.answer_sheet[0].answer_sheet_details ? this.question.answer_sheet[0].answer_sheet_details[0].answer: '' : '': '';
    }
  },
  created() {

    // console.log(SpeechToText);
    // query.set('page', 1);
    // this.actExamEnrolledQuestions(`${this.examEnrollId}?${query.get()}`)
    //   .then(({ items, meta }) => {
    //     this.question = items[0];
    //     this.paritciaptionComponents = slugToComponent(this.question.question_type_slug);

    //     this.answer = this.setAnswer()
    //     this.meta = meta
    //   });
  }
};
</script>

<style></style>
